import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, useCallback, useRef, useState } from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BLACK, GRAY_01, GRAY_03, WHITE } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { appScreenData } from '../../data/petdocApp';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { swiperPagenationStyle } from '../../styles/swiper';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import ScreenshotItem from '../appSection/ScreenshotItem';
import SectionTitleSet from '../common/SectionTitleSet';
import PagenationArrow from '../icon/PagenationArrow';

const AppSection = forwardRef((props, menuActiveRef: ForwardedRef<HTMLDivElement>) => {
	const sectionRef = useRef<HTMLElement>(null);
	const [selectedId, setSelectedId] = useState(0);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Businesses,
		currentStyleType: HeaderStyleType.White,
		nextStyleType: HeaderStyleType.Black,
	});

	const handleClickLeft = useCallback(() => {
		if (selectedId === 0) {
			return;
		}
		setSelectedId((prev) => prev - 1);
	}, [selectedId, appScreenData.length]);

	const handleClickRight = useCallback(() => {
		if (selectedId === appScreenData.length - 1) {
			return;
		}
		setSelectedId((prev) => prev + 1);
	}, [selectedId, appScreenData.length]);

	const onClickScreenshot = useCallback((id: number) => {
		setSelectedId(id);
	}, []);

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.PETDOCAPP} />
			<div ref={menuActiveRef} />
			<Wrapper ref={sectionRef}>
				<TitleWrapper>
					<SectionTitleSet
						title="Petdoc APP"
						description={
							<>
								<span css={showOnDesktopStyle}>
									반려동물등록을 통해 맞춤형 수의사 상담부터 실시간 병원 예약, 케어, 그리고 쇼핑까지
									<br />
									보호자들이 반려동물에 대한 모든 것을 편리하게 해결할 수 있는 모바일 플랫폼
								</span>
								<span css={showOnMobileStyle}>
									반려동물의 케어에 대한 모든 것을
									<br />
									간편하게 해결할 수 있는 모바일 앱
								</span>
							</>
						}
						textAlign="LEFT"
					/>
				</TitleWrapper>
				<ScreenListWrapperDesktop>
					<ScreenList>
						{appScreenData.map((data) => (
							<ScreenshotItem
								key={data.id}
								itemData={data}
								isSelected={data.id === selectedId}
								onClick={onClickScreenshot}
							/>
						))}
					</ScreenList>
					<LeftArrow onClick={handleClickLeft} aria-label="이전 스크린샷 이미지 확대하기">
						<PagenationArrow color={BLACK} direction="LEFT" />
					</LeftArrow>
					<RightArrow onClick={handleClickRight} aria-label="이전 스크린샷 이미지 확대하기">
						<PagenationArrow color={BLACK} direction="RIGHT" />
					</RightArrow>
				</ScreenListWrapperDesktop>
				<ScreenListWrapperMobile>
					<Swiper
						modules={[Pagination, A11y, Autoplay]}
						loop={true}
						pagination={{
							clickable: true,
						}}
						autoplay={{
							delay: 4000,
							disableOnInteraction: false,
						}}
					>
						{appScreenData.map((data) => (
							<SwiperSlide key={data.id}>
								<SlideWrapper>
									<ScreenText>
										<ScreenTitle>{data.text.title}</ScreenTitle>
										<ScreenDescription>{data.text.description}</ScreenDescription>
									</ScreenText>
									<SlideImgMobile src={data.image} alt="" />
								</SlideWrapper>
							</SwiperSlide>
						))}
					</Swiper>
				</ScreenListWrapperMobile>
			</Wrapper>
		</>
	);
});

export default AppSection;

const ButtonStyle = css`
	width: ${pxToRem(19)};
	height: ${pxToRem(26)};
	position: absolute;
	top: 38%;
	left: 50%;
	opacity: 50%;
`;

const RightArrow = styled.button`
	${ButtonStyle};
	transform: translateX(calc(-50% + ${pxToRem(600)}));
`;

const LeftArrow = styled.button`
	${ButtonStyle};
	transform: translateX(calc(-50% - ${pxToRem(600)}));
`;

const ScreenList = styled.ul`
	position: relative;
	display: flex;
	margin: 0 auto;
	width: 100%;
	height: ${pxToRem(553)};
	justify-content: center;
	align-items: center;

	li + li {
		margin-left: ${pxToRem(60)};
	}
`;

const ScreenDescription = styled.p`
	font-size: ${pxToRem(13)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(12)};
	`)}
`;

const ScreenTitle = styled.p`
	font-size: ${pxToRem(16)};
	font-weight: 700;
	margin-bottom: ${pxToRem(6)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(20)};
		font-weight: 500;
		margin-bottom: ${pxToRem(8)};
	`)}
`;

const ScreenText = styled.div`
	margin-bottom: ${pxToRem(32)};

	${getDesktopStyle(css`
		margin-bottom: 0;
	`)}
`;

const SlideImgMobile = styled.img`
	width: 65%;
	box-shadow: 3px 4px 11px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
`;

const SlideWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const ScreenListWrapperMobile = styled.div`
	${showOnMobileStyle};

	.swiper {
		height: calc(130vw + 50px);
	}

	${swiperPagenationStyle({ bgColor: GRAY_03, activeColor: BLACK })};
`;

const ScreenListWrapperDesktop = styled.div`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		position: relative;
	`)}
`;

const TitleWrapper = styled.div`
	margin: 0 auto;
	margin-bottom: ${pxToRem(36)};

	${getDesktopStyle(css`
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
		margin-bottom: ${pxToRem(115)};
	`)}
`;

const Wrapper = styled.section`
	background-color: ${WHITE};
	padding: ${pxToRem(80)} ${pxToRem(24)};
	color: ${BLACK};

	${getDesktopStyle(css`
		background-color: ${GRAY_01};
		padding: ${pxToRem(112)} 0;
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)}
`;
